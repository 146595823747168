<template>
  <div class="flex min-h-screen max-h-screen flex-col">
    <div class="flex-grow">
      <div class="flex mx-auto p-4 w-full justify-center" v-if="getBodyPrint">
        <div class="flex w-full">
          <div class="w-auto">
            <div class="border border-white">
              <bodyprint-view
                :image-source="this.getImageBack"
                :is-active="current_projection === 'back'"
                side="back"
                :flipped="this.back_image_flipped"
                :picture-height="getPictureHeight"
                :picture-width="getPictureWidth"
                :back-guide="getCropBack"
                :side-guide="getCropSide"
              />
            </div>
          </div>
          <div class="w-auto">
            <div class="border border-white">
              <bodyprint-view
                :image-source="this.getImageSide"
                :is-active="current_projection === 'left'"
                side="left"
                :flipped="side_image_flipped"
                :picture-height="getPictureHeight"
                :picture-width="getPictureWidth"
                :back-guide="getCropBack"
                :side-guide="getCropSide"
              />
            </div>
          </div>
          <div class="w-auto" v-if="current_step === 'measure'">
            <measure-set :image-flipped="imageFlippedSet()" />
          </div>
        </div>
        <div
          class="flex-grow flex flex-col justify-between bg-gray-300 -m-4 p-4"
        >
          <div class="flex-grow">
            <div class="text-xl font-semibold text-blue-800 mb-4">
              View bodyprint
            </div>
            <div class="my-4">
              <measure-instructions v-if="current_step === 'measure'" />
            </div>
          </div>
          <div class="w-full bg-gray-200">
            <div class="flex mx-auto p-4">
              <div class="w-full">
                <div class="w-full flex justify-center">
                  <button
                    class="button py-2 px-8 w-40 bg-blue-800 text-white mx-2"
                    @click="goToPreviousStep"
                  >
                    {{ getPreviousStepText() }}
                  </button>
                  <button
                    class="button py-2 px-8 w-40 bg-blue-800 text-white mx-2"
                    @click="goToNextStep"
                  >
                    {{ getNextStepText() }}
                  </button>
                </div>
                <div class="w-full flex justify-center mt-2">
                  <button
                    class="button py-2 px-8 w-full bg-yellow-700 text-white mx-2"
                    @click="() => prepareMeasurementsModal()"
                  >
                    View measurements
                  </button>
                </div>
                <div class="w-full flex justify-center mt-2">
                  <button
                    class="button py-2 px-8 w-full bg-red-700 text-white mx-2"
                    @click="showCancelDialog = true"
                    v-show="this.isQueueItem"
                  >
                    Return
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div>
      <JSON-modal
        :beautifiedJSON="beautifiedJSON"
        :showModal="showJSONModal"
        :onClose="() => (showJSONModal = false)"
      />
    </div>
    <div>
      <measurements-modal
        :loading="measurementsLoading"
        :showModal="showMeasurementsModal"
        :bodyprint-id="measurementsBodyprint"
        :onClose="
          () => {
            showMeasurementsModal = false;
            measurementsBodyprint = null;
          }
        "
      />
    </div>
    <Dialog
      v-if="showCancelDialog"
      @confirmed="toEndScreen"
      @close="showCancelDialog = false"
      color="red"
      title="Are you sure?"
      message="Are you sure you want to close the view of this bodyprint?"
    />
  </div>
</template>

<script>
import Dialog from "../../components/dialog.vue";
import MeasureSet from "../../components/measure/measure-set.vue";
import { mapGetters, mapMutations, mapActions } from "vuex";
import beautify from "json-beautify";
import JSONModal from "../../components/modals/json-modal.vue";
import MeasurementsModal from "../../components/modals/measurements-modal.vue";
import MeasureInstructions from "../../components/measure/measure-instructions.vue";
import { ROUTE_BODYPRINT_QUEUE } from "@/router";
import BodyprintView from "@/components/bodyprint/bodyprint-view.vue";

export default {
  name: "BodyprintCheckView",
  components: {
    BodyprintView,
    Dialog,
    MeasureSet,
    JSONModal,
    MeasurementsModal,
    MeasureInstructions
  },
  created() {
    if (!this.getBodyPrint) {
      // TODO: get bodyprint when it is empty?
      this.$router.replace({ name: ROUTE_BODYPRINT_QUEUE });
      return;
    }

    this.setImageWindowHeight();
    window.addEventListener("resize", this.setImageWindowHeight);
    window.addEventListener("keydown", this.handleKeydown, null);
  },
  beforeDestroy() {
    window.removeEventListener("resize", () => {});
    window.removeEventListener("keydown", this.handleKeydown);
  },
  computed: {
    ...mapGetters({
      getAuth: "auth/getAuth",
      getArea: "configuration/getArea",
      getAreas: "configuration/getAreas",
      getMeasureStep: "bodyprint/getMeasureStep",
      getSelectedMeasurePoint: "bodyprint/getSelectedMeasurePoint",
      getGuide: "bodyprint/getGuide",
      getBodyPrint: "bodyprint/getBodyPrint",
      isQueueItem: "bodyprint/isQueueItem",
      getImageSide: "bodyprint/getImageSide",
      getImageBack: "bodyprint/getImageBack",
      getPictureHeight: "bodyprint/getPictureHeight",
      getPictureWidth: "bodyprint/getPictureWidth",
      getCropBack: "bodyprint/getCropBack",
      getCropSide: "bodyprint/getCropSide"
    }),
    currentStep() {
      if (this.current_step === "start") {
        return "Start editing";
      }
      if (this.current_step === "review") {
        return "Review photos";
      }
      if (this.current_step === "crop") {
        return "Cropping";
      }
      if (this.current_step === "measure") {
        return "Measuring";
      }
      return "";
    },
    beautifiedJSON() {
      return beautify(
        this.$store.getters["bodyprint/getBodyprint"],
        null,
        2,
        80
      );
    }
  },
  data() {
    return {
      measurementsLoading: false,
      showSaveDialog: false,
      showCancelDialog: false,
      rejectionReason: "",
      token: "",
      showProblemModal: false,
      showJSONModal: false,
      showMeasurementsModal: false,
      measurementsBodyprint: null,
      bodyprint: null,
      current_step: "measure",
      current_projection: "back",
      measure_step: 0,
      image_source_back: "lady_orange_back.png",
      image_source_side: "lady_orange_side.png",
      back_crop: {
        x: 0.27,
        y: 0.18,
        width: 0.47,
        height: 0.66,
        projection: "back"
      },
      side_crop: {
        x: 0.31,
        y: 0.2,
        width: 0.44,
        height: 0.6,
        projection: "left"
      },
      back_image_flipped: false,
      side_image_flipped: false
    };
  },
  watch: {
    currentStep() {
      this.setCurrentStep(this.current_step);
    }
  },
  methods: {
    ...mapMutations({
      setActiveCoordinates: "bodyprint/setActiveCoordinates",
      setWindowHeight: "bodyprint/setWindowHeight",
      setCrop: "bodyprint/setCrop",
      setCurrentStep: "bodyprint/setCurrentStep",
      setMeasureStep: "bodyprint/setMeasureStep",
      updateConstraints: "bodyprint/updateConstraints"
    }),
    ...mapActions(["auth/authlogout"]),
    setImageWindowHeight() {
      let payload = {
        height: window.innerHeight,
        width: window.innerWidth
      };
      this.setWindowHeight(payload);
      this.setProjectionCrop(this.getBodyPrint.guides[0].crop, "back");
      this.setProjectionCrop(this.getBodyPrint.guides[1].crop, "left");
    },
    setProjectionCrop(crop, projection) {
      let updatedCrop = {
        x: crop.x,
        x2: crop.x + crop.width,
        y: crop.y,
        y2: crop.y + crop.height,
        width: crop.width,
        height: crop.height
      };
      this.setCrop([updatedCrop, projection]);
    },
    getPreviousStepText() {
      return "Previous";
    },
    getNextStepText() {
      return "Next";
    },
    goToPreviousStep() {
      this.setActiveCoordinates({}); //reset activeCoordinates
      this.measure_step = this.getMeasureStep - 1;
      this.setStepMeasure();
    },
    goToNextStep() {
      this.setActiveCoordinates({}); //reset activeCoordinates
      this.measure_step = this.getMeasureStep + 1;
      this.setStepMeasure();
    },
    setStepMeasure() {
      const payload = {
        areas: this.getAreas,
        step: this.measure_step,
        projection: this.current_projection
      };
      this.updateConstraints(payload);
      this.setMeasureStep(payload);
    },
    toEndScreen() {
      this.$router.replace({
        name: ROUTE_BODYPRINT_QUEUE
      });
    },
    handleKeydown(e) {
      switch (e.code) {
        case "Enter":
          e.preventDefault();
          this.goToNextStep();
          break;
        case "Backspace":
          e.preventDefault();
          this.goToPreviousStep();
          break;
      }
    },
    prepareMeasurementsModal() {
      this.showMeasurementsModal = true;
      this.measurementsBodyprint = this.getBodyPrint._id;
    },
    imageFlippedSet() {
      if (this.getArea.projection === "left") {
        return this.side_image_flipped;
      } else if (this.getArea.projection === "back") {
        return this.back_image_flipped;
      }
      return true;
    }
  }
};
</script>

<style></style>
