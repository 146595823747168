var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"flex flex-col",attrs:{"id":"app"}},[_c('div',{staticClass:"grid grid-cols-12"},[_c('div',{class:[
        _vm.getAuthLoggedIn && _vm.getSidebarOpen ? 'col-span-2' : 'hidden'
      ]},[_c('sidebar')],1),_c('div',{class:[
        _vm.getAuthLoggedIn
          ? _vm.getSidebarOpen
            ? 'col-span-10'
            : 'col-span-12'
          : 'col-span-12'
      ]},[_c('top-bar'),_c('router-view')],1)])])
}
var staticRenderFns = []

export { render, staticRenderFns }