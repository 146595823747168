<template>
  <div>
    <!-- {{imageSource}} -->
    <div
      v-if="isActive"
      class="crop-active-overlay"
      @click="onClickOutsideCrop"
    >
      <crop-bar
        @onStart="onCropStart"
        @onChange="onCropChange"
        @onEnd="onCropEnd"
        bar-position="top"
        :start-position="{
          x: ((crop.x + crop.x2) / 2) * picture_width,
          y: crop.y * picture_height
        }"
        :length="width * picture_width"
        position="vertical"
        :projection="projection"
        :active="selectedBar === 'top'"
        :show="selectedBar === '' || selectedBar === 'top'"
        :flipped="flipped"
      />
      <crop-bar
        @onStart="onCropStart"
        @onChange="onCropChange"
        @onEnd="onCropEnd"
        bar-position="bottom"
        :start-position="{
          x: ((crop.x + crop.x2) / 2) * picture_width,
          y: crop.y2 * picture_height
        }"
        :length="width * picture_width"
        position="vertical"
        :projection="projection"
        :active="selectedBar === 'bottom'"
        :show="selectedBar === '' || selectedBar === 'bottom'"
        :flipped="flipped"
      />
      <crop-bar
        @onStart="onCropStart"
        @onChange="onCropChange"
        @onEnd="onCropEnd"
        bar-position="left"
        :start-position="{
          x: crop.x * picture_width,
          y: ((crop.y + crop.y2) / 2) * picture_height
        }"
        :length="height * picture_height"
        position="horizontal"
        :projection="projection"
        :active="selectedBar === 'left'"
        :show="selectedBar === '' || selectedBar === 'left'"
        :flipped="flipped"
      />
      <crop-bar
        @onStart="onCropStart"
        @onChange="onCropChange"
        @onEnd="onCropEnd"
        bar-position="right"
        :start-position="{
          x: crop.x2 * picture_width,
          y: ((crop.y + crop.y2) / 2) * picture_height
        }"
        :length="height * picture_height"
        position="horizontal"
        :projection="projection"
        :active="selectedBar === 'right'"
        :show="selectedBar === '' || selectedBar === 'right'"
        :flipped="flipped"
      />
      <div
        :class="
          selectedBar === '' ? 'outside-non-selected' : 'outside-selected'
        "
        :style="topOverlayStyle"
      />
      <div
        :class="
          selectedBar === '' ? 'outside-non-selected' : 'outside-selected'
        "
        :style="bottomOverlayStyle"
      />

      <div
        :class="
          selectedBar === '' ? 'outside-non-selected' : 'outside-selected'
        "
        :style="leftOverlayStyle"
      />
      <div
        :class="
          selectedBar === '' ? 'outside-non-selected' : 'outside-selected'
        "
        :style="rightOverlayStyle"
      />

      <svg
        class="absolute z-10"
        :height="picture_height"
        :width="picture_width"
      >
        <rect
          x="0"
          y="0"
          :width="picture_width"
          :height="picture_height"
          :style="
            selectedBar === ''
              ? 'fill:rgba(0, 0, 0, 0.0)'
              : 'fill:rgba(0, 0, 0, 0.6)'
          "
        />
        <line
          :x1="leftOverlayStyle.width"
          y1=""
          :x2="leftOverlayStyle.width"
          :y2="picture_height"
          style="stroke:rgb(255,127,80);stroke-width:2"
        />
        <line
          :x1="rightOverlayStyle.left"
          y1=""
          :x2="rightOverlayStyle.left"
          :y2="picture_height"
          style="stroke:rgb(255,127,80);stroke-width:2"
        />
        <line
          :y1="topOverlayStyle.height"
          :y2="topOverlayStyle.height"
          :x2="picture_width"
          style="stroke:rgb(255,127,80);stroke-width:2"
        />
        <line
          :y1="bottomOverlayStyle.top"
          :y2="bottomOverlayStyle.top"
          :x2="picture_width"
          style="stroke:rgb(255,127,80);stroke-width:2"
        />
      </svg>
    </div>
    <div v-if="!isActive" class="crop-inactive-overlay" />
    <img
      class="w-auto h-full"
      :src="imageSource"
      :style="imageStyle"
      alt="Projection image"
    />
  </div>
</template>

<script>
import CropBar from "./crop-bar";
import controlMixin from "../control-mixin";
import { mapMutations, mapGetters } from "vuex";

export default {
  props: {
    imageSource: String,
    projection: String,
    isActive: Boolean,
    flipped: Boolean,
    initialCrop: Object
  },
  components: {
    CropBar
  },
  mixins: [controlMixin],
  data() {
    return {
      cropBars: ["top", "right", "bottom", "left"],
      selectedBar: "",
      crop: {
        x: 0,
        x2: 0,
        y: 0,
        y2: 0,
        width: 0,
        height: 0
      }
      //picture_height: 1000,
      //picture_width: 750
    };
  },
  created() {
    this.crop.x = this.initialCrop.x;
    this.crop.y = this.initialCrop.y;
    this.crop.x2 = this.initialCrop.x + this.initialCrop.width;
    this.crop.y2 = this.initialCrop.y + this.initialCrop.height;
  },
  mounted() {
    window.addEventListener("keydown", this.handleKeydown, null);
  },
  beforeDestroy() {
    window.removeEventListener("keydown", this.handleKeydown);
  },
  computed: {
    ...mapGetters({ getGuide: "bodyprint/getGuide" }),
    // bodyprintCrop() {
    //   return this.getGuide(this.initialCrop.projection).crop
    // },
    imageStyle() {
      return `height:${this.picture_height}px!important; transform:${
        this.flipped ? "scaleX(-1)" : "scaleX(1)"
      }`;
    },
    picture_width() {
      return this.$store.getters["bodyprint/getPictureWidth"];
    },
    picture_height() {
      return this.$store.getters["bodyprint/getPictureHeight"];
    },
    width() {
      return this.crop.x2 - this.crop.x;
    },
    height() {
      return this.crop.y2 - this.crop.y;
    },
    topOverlayStyle() {
      var x = 0;
      var y = 0;
      var width = this.picture_width;
      var height = this.picture_height * this.crop.y;
      return {
        position: "absolute",
        top: y + "px",
        left: x + "px",
        width: width + "px",
        height: height + "px"
      };
    },
    bottomOverlayStyle() {
      var x = 0;
      var y = (this.crop.y + this.height) * this.picture_height;
      var width = this.picture_width;
      var height = this.picture_height - y;
      return {
        position: "absolute",
        top: y + "px",
        left: x + "px",
        width: width + "px",
        height: height + "px"
      };
    },
    leftOverlayStyle() {
      var x = 0;
      var y = this.crop.y * this.picture_height;
      var width = this.crop.x * this.picture_width;
      var height = this.picture_height * this.height;
      return {
        position: "absolute",
        top: y + "px",
        // top: "0px",
        left: x + "px",
        width: width + "px",
        // height: "1000px",
        height: height + "px"
      };
    },
    rightOverlayStyle() {
      var x = (this.crop.x + this.width) * this.picture_width;
      var y = this.crop.y * this.picture_height;
      var width = this.picture_width - x;
      var height = this.picture_height * this.height;
      return {
        position: "absolute",
        top: y + "px",
        left: x + "px",
        width: width + "px",
        height: height + "px"
      };
    }
  },
  methods: {
    ...mapMutations({
      setCrop: "bodyprint/setCrop",
      deactivatePoints: "bodyprint/deactivatePoints"
    }),
    onCropStart(position) {
      this.selectedBar = position;
    },
    onCropEnd() {
      this.selectedBar = "";
      this.deactivatePoints();
    },
    onCropChange(change) {
      const x = change.x / this.picture_width;
      const y = change.y / this.picture_height;
      if (change.position === "top" && y + 0.055 <= this.crop.y2 && y >= 0) {
        this.crop.y = y;
      }
      if (change.position === "left" && x + 0.074 <= this.crop.x2 && x >= 0) {
        this.crop.x = x;
      }
      if (change.position === "bottom" && y - 0.055 >= this.crop.y && y <= 1) {
        this.$set(this.crop, "y2", y);
      }
      if (change.position === "right" && x - 0.074 >= this.crop.x && x <= 1) {
        this.$set(this.crop, "x2", x);
      }
      if (typeof this.crop != "undefined") {
        this.setCrop([this.crop, this.projection]);
      }
    },
    handleKeydown(e) {
      switch (e.code) {
        case "Space":
          e.preventDefault();
          if (this.selectedBar !== "") {
            this.onCropEnd();
          } else {
            this.onCropStart(this.cropBars[0]);
          }
          break;
        case "Tab":
          e.preventDefault();
          var currentIndex = this.cropBars.indexOf(this.selectedBar);
          var newIndex = currentIndex;
          if (currentIndex === this.cropBars.length - 1) {
            newIndex = 0;
          } else {
            newIndex++;
          }
          this.onCropStart(this.cropBars[newIndex]);
          break;
      }
    },
    onClickOutsideCrop(event) {
      var isCropBar = false;
      event.composedPath().forEach(element => {
        if (element.classList) {
          if (element.classList.contains("crop-bar-active")) {
            isCropBar = true;
          }
        }
      });
      if (!isCropBar) {
        if (this.selectedBar !== "") {
          this.onCropEnd();
        }
      }
    }
  },
  watch: {
    //   bodyprintCrop() {
    //     console.log("updating crop")
    //     this.crop = { ...this.bodyprintCrop }
    //     this.crop.x2 = this.crop.x + this.crop.width
    //     this.crop.y2 = this.crop.y + this.crop.height
    //   }
  }
};
</script>
