<template>
  <div class="view" :style="cropStyle">
    <img :style="imageStyle" :src="imageSource" alt="Measure image" />
    <bodyprint-skeleton
      class="absolute"
      :projection="side"
      :crop="cropGuides"
      :width="pictureWidth"
      :height="pictureHeight"
      :zoom="cropGuides.growthFactor"
      :heightZoom="1"
    />

    <!-- <div class="points absolute top-0 left-0">
      <measure-point v-for="point in 43" :key="point" :point-number="point" :side="side" />
    </div>-->
  </div>
</template>

<script>
import BodyprintSkeleton from "./bodyprint-skeleton.vue";

export default {
  components: {
    BodyprintSkeleton
  },
  computed: {
    cropGuides() {
      if (this.side === "back") {
        return this.backGuide.crop;
      }
      return this.sideGuide.crop;
    },
    imageStyle() {
      return {
        height: this.pictureHeight * this.cropGuides.growthFactor + "px",
        marginTop:
          "-" +
          this.cropGuides.y *
            this.pictureHeight *
            this.cropGuides.growthFactor +
          "px",
        marginLeft:
          "-" +
          this.cropGuides.x *
            this.pictureWidth *
            this.cropGuides.growthFactor +
          "px",
        transform: this.flipped ? "scaleX(-1)" : "scaleX(1)"
      };
    },
    cropStyle() {
      return {
        position: "relative",
        height: this.pictureHeight + "px",
        width:
          this.cropGuides.width *
            this.pictureWidth *
            this.cropGuides.growthFactor +
          "px",
        // border: "2px solid red",
        overflow: "hidden",
        marginRight: "5px"
      };
    }
  },
  props: {
    pictureHeight: {
      type: Number,
      default: 750
    },
    pictureWidth: {
      type: Number,
      default: 750
    },
    backGuide: Object,
    sideGuide: Object,
    imageSource: String,
    isActive: Boolean,
    side: String,
    flipped: Boolean
  },
  data() {
    return {
      points: 43,
      growthFactor: ""
    };
  }
};
</script>

<style>
.view {
  position: relative;
}
img {
  max-width: none !important;
}
</style>
