<template>
  <div class="flex">
    <div class="flex mx-auto p-4 w-full h-full justify-center">
      <div
        class="flex w-full h-full justify-center text-blue-800 my-12"
        style="text-align: center"
      >
        <div v-if="dataProvided">
          <i v-if="!failed" class="fas fa-circle-notch fa-spin fa-5x"></i>
          <div v-if="!failed" class="text-xl mt-4">Loading bodyprint</div>
          <div v-if="failed" class="text-xl">Failed to load bodyprint</div>
        </div>
        <div class="w-3/4" v-else>
          <div v-show="!loading">
            <div class="flex flex-row justify-evenly mb-2">
              <div class="w-1/3" v-if="getAuth.bodyprintQueue === true">
                <p class="text-xl font-bold mt-5">
                  Get next bodyprint in queue
                </p>
                <button
                  @click="getQueueItem()"
                  class="text-xl p-3 m-2 bg-blue-800 text-white"
                >
                  Get queue item
                </button>
              </div>

              <div
                class="m-10"
                v-if="
                  getAuth.bodyprintQueue === true &&
                    getAuth.bodyprintSearch === true
                "
              >
                <span>
                  Or
                </span>
              </div>

              <div class="grid grid-cols-12 w-1/3">
                <p class="col-span-12 text-xl font-bold mt-5">
                  Get bodyprints of selected user
                </p>
                <div class="col-span-12 mt-3 flex-col">
                  <label
                    for="email_address"
                    class="block font-medium text-xl text-gray-700"
                    >Email address</label
                  >
                  <div class="flex flex-row justify-center">
                    <input
                      id="email_address"
                      type="text"
                      v-model="email"
                      autocomplete="email"
                      placeholder="Email address"
                      class="
                        mt-1
                        p-2
                        border-2 border-solid border-black
                        rounded-md
                        "
                    />
                    <button class="ml-2" @click="clearEmail()">
                      Clear
                    </button>
                  </div>
                </div>
                <div class="col-span-12">
                  <button
                    @click="searchQueue()"
                    :class="{
                      'text-xl p-3 m-5 mb-2 text-white': true,
                      'bg-blue-800': emailFilled,
                      'bg-gray-600': !emailFilled
                    }"
                    :disabled="!emailFilled"
                  >
                    Search for bodyprints
                  </button>
                </div>
                <p class="col-span-12 text-red-500">{{ this.errorMessage }}</p>
              </div>
            </div>
            <div
              class="flex flex-row w-full justify-center"
              v-if="
                getAuth.bodyprintSearch === true ||
                  getAuth.bodyprintList === true
              "
            >
              <div class="w-full">
                <p class="text-xl font-bold mt-5">
                  Bodyprints
                </p>
                <div
                  class="table-fixed w-full border-solid p-4 border-gray-200 border-2 mt-3 data-table overflow-y-auto"
                  v-if="tableLoading"
                >
                  <p class="text-lg">Loading bodyprints...</p>
                </div>
                <table
                  class="table-fixed w-full border-solid px-4 border-gray-200 border-2 mt-3 data-table overflow-y-auto"
                  v-else-if="bodyprints.length > 0"
                >
                  <thead>
                    <tr>
                      <th class="w-3/12">User</th>
                      <th class="w-2/12">Created at</th>
                      <th class="w-2/12">Last updated at</th>
                      <th class="w-3/12">Last editor</th>
                      <th class="w-1/12">Retailer code</th>
                      <th class="w-1/12">Status</th>
                      <th class="w-1/12">User permission</th>
                      <th />
                    </tr>
                  </thead>
                  <tbody>
                    <tr
                      v-for="(bodyprint, index) in this.bodyprints"
                      :key="index"
                    >
                      <td>
                        {{ bodyprint.user ? bodyprint.user.email : "-" }}
                      </td>
                      <td>{{ bodyprint.created_at }}</td>
                      <td>{{ bodyprint.last_updated_at }}</td>
                      <td>{{ bodyprint.last_editor }}</td>
                      <td>{{ bodyprint.retailer_code }}</td>
                      <td>{{ bodyprint.status }}</td>
                      <td>
                        {{ bodyprint.user ? bodyprint.user.permission : "-" }}
                      </td>
                      <td>
                        <div class="flex">
                          <button
                            @click="requestBodyprintToView(bodyprint.id)"
                            class="text-md p-2 m-2 text-white bg-blue-800"
                          >
                            View
                          </button>
                          <button
                            :disabled="bodyprint.status !== 'COMPLETED'"
                            @click="requestBodyprintSizes(bodyprint.id)"
                            :class="{
                              'text-md p-2 m-2 text-white': true,
                              'bg-blue-800': bodyprint.status === 'COMPLETED',
                              'bg-gray-600': bodyprint.status !== 'COMPLETED'
                            }"
                          >
                            Sizes
                          </button>
                          <button
                            :disabled="!bodyprint.editable"
                            @click="requestBodyprint(bodyprint.id)"
                            :class="{
                              'text-md p-2 m-2 text-white': true,
                              'bg-blue-800': bodyprint.editable,
                              'bg-gray-600': !bodyprint.editable
                            }"
                          >
                            Edit
                          </button>
                        </div>
                      </td>
                    </tr>
                  </tbody>
                </table>
                <div v-else>
                  <p v-if="tableLoading === false">
                    No bodyprints found
                  </p>
                  <i v-else class="fas fa-circle-notch fa-spin fa-5x mt-5"></i>
                </div>
              </div>
            </div>
          </div>
          <i
            v-if="!failed && loading"
            class="fas fa-circle-notch fa-spin fa-5x"
          ></i>
          <div v-if="!failed && loading" class="text-xl mt-4">
            Loading bodyprint
          </div>
          <!-- <div v-else class="text-xl">Failed to load bodyprint</div> -->
          <button v-show="failed && !getQueueEmpty" @click="removeFromQueue()">
            Reject bodyprint
          </button>
          <div v-if="failed && getQueueEmpty" class="text-xl">
            <p>Queue is empty</p>
            <br />
            <button @click="backFromEmpty">Back</button>
          </div>
        </div>
      </div>
    </div>

    <measurements-modal
      :loading="measurementsLoading"
      :showModal="showMeasurementsModal"
      :bodyprint-id="measurementsBodyprint"
      :onClose="() => (showMeasurementsModal = false)"
    />
  </div>
</template>

<script>
import MeasurementsModal from "@/components/modals/measurements-modal.vue";
import { mapActions, mapGetters } from "vuex";
import moment from "moment";
import { ROUTE_BODYPRINT_CHECK, ROUTE_BODYPRINT_EDIT } from "@/router";

export default {
  name: "BodyprintQueueView",
  components: {
    MeasurementsModal
  },
  computed: {
    ...mapGetters({
      getAuthLoggedIn: "auth/getAuthLoggedIn",
      getAuth: "auth/getAuth",
      getQueueEmpty: "bodyprint/getQueueEmpty",
      getSidebarOpen: "layout/getSidebarOpen"
    }),
    ...mapActions(["auth/authcheck", "layout/toggleSidebar"])
  },
  data() {
    return {
      dataProvided: false,
      loading: false,
      tableLoading: false,
      loaded: false,
      failed: false,
      email: null,
      emailFilled: false,
      code: null,
      errorMessage: null,
      measurementsLoading: false,
      showMeasurementsModal: false,
      measurementsBodyprint: null,
      bodyprints: []
    };
  },
  methods: {
    backFromEmpty() {
      window.location.reload();
    },
    loadBodyPrint() {
      if (!this.$route.params.id && !this.$route.params.bodyprint_id) {
        this.dataProvided = false;
        // this.loaded = true;

        if (this.getAuth.bodyprintList === true) {
          this.getRetailerBodyprints();
        }
      } else if (
        this.$route.params.id !== false &&
        this.$route.bodyprint_id !== false
      ) {
        this.dataProvided = true;
        var user_id = this.$route.params.id;
        var bodyprint_id = this.$route.params.bodyprint_id;

        this.$store
          .dispatch("bodyprint/getBodyprint", {
            id: user_id,
            bodyprint_id: bodyprint_id
          })
          .then(() => {
            this.$store.dispatch("bodyprint/getBodyprintSizes", {
              user: user_id,
              bodyprint: bodyprint_id
            });
            this.$store.dispatch("bodyprint/checkQueue", bodyprint_id);
            this.$store
              .dispatch("bodyprint/getImages", user_id)
              .then(() => {
                this.loaded = true;
                this.$store.dispatch("bodyprint/calculateBodyprint");
              })
              .catch(() => {
                this.failed = true;
              });
          })
          .catch(() => {
            this.failed = true;
          });
      }
    },
    removeFromQueue() {
      var payload = {
        status: "REJECTED",
        rejection_reason: "Bodyprint corrupted"
      };
      this.$store.dispatch(
        "bodyprint/setQueueItemStatus",
        JSON.stringify(payload)
      );
    },
    getQueueItem() {
      this.loading = true;
      this.$store
        .dispatch("bodyprint/getFirstInQueue")
        .then(() => {
          var bp = this.$store.getters["bodyprint/getBodyprint"];
          if (bp.user) {
            this.$store.dispatch("bodyprint/getBodyprintSizes", {
              user: bp.user,
              bodyprint: bp._id
            });
            this.$store
              .dispatch("bodyprint/getImages", bp.user)
              .then(() => {
                this.loaded = true;
                this.$store.dispatch("bodyprint/calculateBodyprint");
              })
              .catch(() => {
                this.failed = true;
              });
          }
          if (this.$store.getters["bodyprint/getQueueEmpty"]) {
            this.failed = true;
          }
        })
        .catch(() => {
          this.failed = true;
        });
    },
    searchEmail() {
      this.loading = true;
      this.$store
        .dispatch("bodyprint/getBodyprintByUser", {
          email: this.email,
          code: this.code
        })
        .then(() => {
          var bp = this.$store.getters["bodyprint/getBodyPrint"];
          if (bp.user) {
            this.$store.dispatch("bodyprint/getBodyprintSizes", {
              user: bp.user,
              bodyprint: bp._id
            });
            this.$store
              .dispatch("bodyprint/getImages", bp.user)
              .then(() => {
                this.loaded = true;
                this.$store.dispatch("bodyprint/calculateBodyprint");
              })
              .catch(() => {
                this.loading = false;
                this.failed = true;
              });
          }
        })
        .catch(() => {
          this.loading = false;
          this.failed = true;
          this.errorMessage = "Could not find a bodyprint for this user.";
        });
    },
    searchQueue() {
      if (this.email === "" && this.getAuth.bodyprintList === true) {
        this.getRetailerBodyprints();
      } else {
        this.bodyprints = [];
        this.tableLoading = true;
        this.$store
          .dispatch("bodyprint/getBodyprintsByUser", {
            email: this.email,
            code: this.code
          })
          .then(() => {
            this.bodyprints = this.sortedBodyprints();
            this.tableLoading = false;
            this.errorMessage = null;
          })
          .catch(() => {
            this.tableLoading = false;
            this.errorMessage = "Could not find a bodyprint for this user.";
          });
      }
    },
    getRetailerBodyprints() {
      this.bodyprints = [];
      this.tableLoading = true;
      this.$store
        .dispatch("bodyprint/getBodyprintsForRetailer", {})
        .then(() => {
          this.bodyprints = this.sortedBodyprints();
          this.tableLoading = false;
          this.errorMessage = null;
        })
        .catch(() => {
          this.tableLoading = false;
          this.errorMessage = "Could not find bodyprints for this retailer.";
        });
    },
    requestBodyprintToView(id) {
      if (this.getSidebarOpen) {
        this.$store.dispatch("layout/toggleSidebar");
      }
      this.loading = true;
      this.$store
        .dispatch("bodyprint/requestBodyprintToView", { id: id })
        .then(() => {
          var bp = this.$store.getters["bodyprint/getBodyPrint"];
          if (bp.user) {
            this.$store.dispatch("bodyprint/getBodyprintSizes", {
              user: bp.user,
              bodyprint: bp._id
            });
            this.$store
              .dispatch("bodyprint/getImages", bp.user)
              .then(() => {
                this.loaded = true;
                this.$store.dispatch("bodyprint/checkBodyprint").then(() => {
                  this.$router.push({
                    name: ROUTE_BODYPRINT_CHECK,
                    params: { bodyprint_id: bp._id }
                  });
                });
              })
              .catch(() => {
                this.failed = true;
              });
          }
        })
        .catch(() => {
          this.loading = false;
          this.errorMessage = "Could not find a bodyprint for this user.";
        });
    },
    requestBodyprint(id) {
      if (this.getSidebarOpen) {
        this.$store.dispatch("layout/toggleSidebar");
      }
      this.loading = true;
      this.$store
        .dispatch("bodyprint/requestBodyprint", { id: id })
        .then(() => {
          var bp = this.$store.getters["bodyprint/getBodyPrint"];
          if (bp.user) {
            this.$store.dispatch("bodyprint/getBodyprintSizes", {
              user: bp.user,
              bodyprint: bp._id
            });
            this.$store
              .dispatch("bodyprint/getImages", bp.user)
              .then(() => {
                this.loaded = true;
                this.$store.dispatch("bodyprint/editBodyprint").then(() => {
                  this.$router.push({
                    name: ROUTE_BODYPRINT_EDIT,
                    params: { bodyprint_id: bp._id }
                  });
                });
              })
              .catch(() => {
                this.failed = true;
              });
          }
        })
        .catch(() => {
          this.loading = false;
          this.errorMessage = "Could not find a bodyprint for this user.";
        });
    },
    requestBodyprintSizes(id) {
      this.measurementsLoading = true;
      this.$store
        .dispatch("bodyprint/requestBodyprintSizes", { id: id })
        .then(() => {
          this.measurementsLoading = false;
          this.showMeasurementsModal = true;
          this.measurementsBodyprint = id;
        })
        .catch(() => {
          this.measurementsBodyprint = null;
          this.measurementsLoading = false;
          this.errorMessage = "Could not find a bodyprint for this user.";
        });
    },
    requestBodyPrintUserSizes() {
      this.loading = true;
      this.$store
        .dispatch("bodyprint/getBodyprintByUserRaw", {
          email: this.email,
          code: this.code
        })
        .then(() => {
          this.loaded = true;
          this.loading = false;
          this.showMeasurementsModal = true;
        })
        .catch(() => {
          this.loading = false;
          this.errorMessage = "Could not find a bodyprint for this user.";
        });
    },
    clearEmail() {
      this.email = "";
      this.searchQueue();
    },
    sortedBodyprints() {
      return this.$store.getters["bodyprint/getBodyPrints"].sort((a, b) => {
        const momentA = moment(a.last_updated_at, "DD-MM-YYYY hh:mm:ss");
        const momentB = moment(b.last_updated_at, "DD-MM-YYYY hh:mm:ss");

        if (momentA.isBefore(momentB)) {
          return 1;
        } else if (momentA.isAfter(momentB)) {
          return -1;
        } else {
          return 0;
        }
      });
    }
  },
  created() {
    if (this.getAuthLoggedIn) {
      this.loadBodyPrint();
    }
  },
  watch: {
    getAuthLoggedIn() {
      if (this.getAuthLoggedIn) {
        this.loadBodyPrint();
      }
    },
    email(newEmail) {
      this.emailFilled = newEmail !== "";
    }
  }
};
</script>
