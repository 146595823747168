<template>
  <div class="flex min-h-screen max-h-screen flex-col">
    <div class="flex-grow">
      <div class="flex mx-auto p-4 w-full justify-center" v-if="getBodyPrint">
        <div class="flex w-full">
          <div class="w-auto">
            <div class="border border-white">
              <crop-view
                v-if="current_step === 'crop'"
                :image-source="this.getImageBack"
                :projection="'back'"
                :is-active="current_projection === 'back'"
                :initial-crop="this.getGuide('back').crop"
                side="back"
                :flipped="this.back_image_flipped"
              />
              <bodyprint-view
                v-if="current_step === 'measure'"
                :image-source="this.getImageBack"
                :is-active="current_projection === 'back'"
                side="back"
                :flipped="this.back_image_flipped"
                :picture-height="getPictureHeight"
                :picture-width="getPictureWidth"
                :back-guide="getCropBack"
                :side-guide="getCropSide"
              />
            </div>
          </div>
          <div class="w-auto">
            <div class="border border-white">
              <crop-view
                v-if="current_step === 'crop'"
                :image-source="this.getImageSide"
                :projection="'left'"
                :is-active="current_projection === 'left'"
                :initial-crop="this.getGuide('left').crop"
                side="left"
                :flipped="side_image_flipped"
              />
              <bodyprint-view
                v-if="current_step === 'measure'"
                :image-source="this.getImageSide"
                :is-active="current_projection === 'left'"
                side="left"
                :flipped="side_image_flipped"
                :picture-height="getPictureHeight"
                :picture-width="getPictureWidth"
                :back-guide="getCropBack"
                :side-guide="getCropSide"
              />
            </div>
          </div>
          <div class="w-auto" v-if="current_step === 'measure'">
            <measure-set :image-flipped="imageFlippedSet()" />
          </div>
        </div>
        <div
          class="flex-grow flex flex-col justify-between bg-gray-300 -m-4 p-4"
        >
          <div class="flex-grow">
            <div class="text-xl font-semibold text-blue-800 mb-4">
              {{
                this.current_step === "crop"
                  ? "Crop Instructions"
                  : "Measure instructions"
              }}
            </div>
            {{ "current step is: " + this.current_step }}
            <!-- <p class="text-base mb-4">
              Move the red handles to the body, so that the edges touch the
              dotted line of the crop handles.
            </p>
            <p class="text-base mb-4">
              Once all the crop handles have been correctly placed you can use
              next to crop the next view.
            </p>-->
            <div class="my-4">
              <crop-instructions
                v-if="current_step === 'crop'"
                :projection="current_projection"
              />
            </div>
            <div class="my-4">
              <measure-instructions v-if="current_step === 'measure'" />
            </div>
          </div>
          <!-- controls -->
          <div>
            <controls />
            <div class="w-full bg-gray-200">
              <div class="flex mx-auto p-4">
                <div class="w-full">
                  <div class="w-full flex justify-center">
                    <button
                      class="button py-2 px-8 w-40 bg-gray-200 text-white mx-2"
                      :disabled="true"
                      v-if="
                        this.current_step === 'crop' &&
                          this.current_projection === 'back'
                      "
                    ></button>
                    <button
                      class="button py-2 px-8 w-40 bg-blue-800 text-white mx-2"
                      @click="goToPreviousStep"
                      v-else
                    >
                      {{ getPreviousStepText() }}
                    </button>
                    <button
                      class="button py-2 px-4 bg-red-700 text-white"
                      @click="() => (showProblemModal = true)"
                    >
                      Reject bodyprint
                    </button>
                    <!--                  <button-->
                    <!--                    class="button py-2 px-4 bg-yellow-700 text-white"-->
                    <!--                    @click="() => (showJSONModal = true)"-->
                    <!--                  >-->
                    <!--                    Show JSON-->
                    <!--                  </button>-->
                    <button
                      class="button py-2 px-8 w-40 bg-blue-800 text-white mx-2"
                      @click="goToNextStep"
                      :disabled="!checkCrop"
                      v-bind:class="!checkCrop ? 'cursor-not-allowed' : ''"
                    >
                      {{ getNextStepText() }}
                    </button>
                    <!-- <button
                      class="button py-2 px-8 w-40 bg-blue-800 text-white mx-2"
                      @click="saveBodyprint"
                      v-show="this.current_step == 'measure'"
                      v-bind:class="!checkCrop ? 'cursor-not-allowed' : ''"
                    >
                      Save
                    </button> -->
                  </div>
                  <div
                    class="w-full flex justify-center mt-2"
                    v-show="this.current_step === 'crop'"
                  >
                    <button
                      class="button py-2 px-8 w-full bg-blue-800 text-white mx-2"
                      @click="() => (side_image_flipped = !side_image_flipped)"
                    >
                      Flip side image
                    </button>
                  </div>
                  <div class="w-full flex justify-center mt-2">
                    <button
                      class="button py-2 px-8 w-full bg-blue-800 text-white mx-2"
                      @click="returnToCropping"
                      v-show="this.current_step === 'measure'"
                    >
                      Back to cropping
                    </button>
                  </div>
                  <div
                    class="w-full flex justify-center mt-2"
                    v-show="this.current_step === 'measure'"
                  >
                    <button
                      class="button py-2 px-8 w-full bg-yellow-700 text-white mx-2"
                      @click="() => prepareMeasurementsModal()"
                    >
                      View measurements
                    </button>
                    <!-- <button
                      class="button py-2 px-8 w-full bg-yellow-700 text-white mx-2"
                      @click="showUpdateDialog = true"
                    >
                      Update bodyprint
                    </button> -->
                  </div>
                  <div class="w-full flex justify-center mt-2">
                    <button
                      class="button py-2 px-8 w-full bg-blue-800 text-white mx-2"
                      @click="showSaveDialog = true"
                      v-show="
                        this.current_step === 'measure' && this.isQueueItem
                      "
                    >
                      Save and finalize
                    </button>
                  </div>
                  <div class="w-full flex justify-center mt-2">
                    <button
                      class="button py-2 px-8 w-full bg-red-700 text-white mx-2"
                      @click="showCancelDialog = true"
                      v-show="this.isQueueItem"
                    >
                      Cancel
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div>
      <problem-modal
        :showModal="showProblemModal"
        :onClose="() => (showProblemModal = false)"
        :onSubmit="reason => rejectBodyPrint(reason)"
      />
    </div>
    <div>
      <JSON-modal
        :beautifiedJSON="beautifiedJSON"
        :showModal="showJSONModal"
        :onClose="() => (showJSONModal = false)"
      />
    </div>
    <div>
      <measurements-modal
        :loading="measurementsLoading"
        :showModal="showMeasurementsModal"
        :bodyprint-id="measurementsBodyprint"
        :onClose="
          () => {
            showMeasurementsModal = false;
            measurementsBodyprint = null;
          }
        "
      />
    </div>
    <Dialog
      v-if="showCancelDialog"
      @confirmed="stopEditing"
      @close="showCancelDialog = false"
      color="red"
      title="Are you sure?"
      message="Are you sure you want to cancel editting this bodyprint and reset back to the previous version?"
    />
    <Dialog
      v-if="showSaveDialog"
      @confirmed="completeBodyPrint"
      @close="showSaveDialog = false"
      title="Are you sure?"
      message="Are you sure you want to save and finalize this bodyprint?"
    />
  </div>
</template>

<script>
import CropView from "../../components/crop/crop-view.vue";
import Dialog from "../../components/dialog.vue";
import MeasureSet from "../../components/measure/measure-set.vue";
import Controls from "../../components/controls.vue";
import { mapGetters, mapMutations, mapActions } from "vuex";
import beautify from "json-beautify";
import CropInstructions from "../../components/crop/crop-instructions.vue";
import JSONModal from "../../components/modals/json-modal.vue";
import ProblemModal from "../../components/modals/problem-modal.vue";
import MeasurementsModal from "../../components/modals/measurements-modal.vue";
import MeasureInstructions from "../../components/measure/measure-instructions.vue";
import { ROUTE_BODYPRINT_QUEUE } from "@/router";
import BodyprintView from "@/components/bodyprint/bodyprint-view.vue";

export default {
  name: "BodyprintEditView",
  components: {
    BodyprintView,
    CropView,
    Dialog,
    MeasureSet,
    Controls,
    JSONModal,
    ProblemModal,
    MeasurementsModal,
    MeasureInstructions,
    CropInstructions
  },
  created() {
    if (!this.getBodyPrint) {
      // TODO: get bodyprint when it is empty?
      this.$router.replace({ name: ROUTE_BODYPRINT_QUEUE });
      return;
    }

    this.setImageWindowHeight();
    window.addEventListener("resize", this.setImageWindowHeight);
    window.addEventListener("keydown", this.handleKeydown, null);
  },
  beforeDestroy() {
    window.removeEventListener("resize", () => {});
    window.removeEventListener("keydown", this.handleKeydown);
  },
  computed: {
    ...mapGetters({
      getAuth: "auth/getAuth",
      getArea: "configuration/getArea",
      getAreas: "configuration/getAreas",
      getMeasureStep: "bodyprint/getMeasureStep",
      getGuide: "bodyprint/getGuide",
      getBodyPrint: "bodyprint/getBodyPrint",
      isQueueItem: "bodyprint/isQueueItem",
      getImageSide: "bodyprint/getImageSide",
      getImageBack: "bodyprint/getImageBack",
      getPictureHeight: "bodyprint/getPictureHeight",
      getPictureWidth: "bodyprint/getPictureWidth",
      getCropBack: "bodyprint/getCropBack",
      getCropSide: "bodyprint/getCropSide"
    }),
    showCrop() {
      return this.current_step === "start" || this.current_step === "crop";
    },
    checkCrop() {
      let crop = this.getGuide(this.current_projection).crop;
      let bodyprint = this.getBodyPrint;
      return !!(bodyprint.points.length > 0 || crop.x2);
    },
    currentStep() {
      if (this.current_step === "start") {
        return "Start editing";
      }
      if (this.current_step === "review") {
        return "Review photos";
      }
      if (this.current_step === "crop") {
        return "Cropping";
      }
      if (this.current_step === "measure") {
        return "Measuring";
      }
      return "";
    },
    // currentImage() {
    //     return this.getGuide(this.current_projection).image_source
    // },
    beautifiedJSON() {
      return beautify(this.$store.getters["bodyPrint"], null, 2, 80);
    }
  },
  data() {
    return {
      measurementsLoading: false,
      showSaveDialog: false,
      showCancelDialog: false,
      rejectionReason: "",
      token: "",
      showProblemModal: false,
      showJSONModal: false,
      showMeasurementsModal: false,
      measurementsBodyprint: null,
      bodyprint: null,
      current_step: "crop",
      current_projection: "back",
      measure_step: 0,
      image_source_back: "lady_orange_back.png",
      image_source_side: "lady_orange_side.png",
      back_crop: {
        x: 0.27,
        y: 0.18,
        width: 0.47,
        height: 0.66,
        projection: "back"
      },
      side_crop: {
        x: 0.31,
        y: 0.2,
        width: 0.44,
        height: 0.6,
        projection: "left"
      },
      back_image_flipped: false,
      side_image_flipped: false
    };
  },
  watch: {
    currentStep() {
      this.setCurrentStep(this.current_step);
    }
  },
  methods: {
    ...mapMutations({
      setActiveCoordinates: "bodyprint/setActiveCoordinates",
      setWindowHeight: "bodyprint/setWindowHeight",
      setCurrentStep: "bodyprint/setCurrentStep",
      setMeasureStep: "bodyprint/setMeasureStep",
      updateConstraints: "bodyprint/updateConstraints"
    }),
    ...mapActions(["auth/authlogout"]),
    ...mapActions({
      postBodyprint: "bodyprint/postBodyprint",
      setQueueItemStatus: "bodyprint/setQueueItemStatus",
      cancelQueueItem: "bodyprint/cancelQueueItem",
      getBodyprintSizes: "bodyprint/getBodyprintSizes"
    }),
    setImageWindowHeight() {
      let payload = {
        height: window.innerHeight,
        width: window.innerWidth
      };
      this.setWindowHeight(payload);
    },
    getPreviousStepText() {
      if (this.current_step === "crop") {
        if (this.current_projection === "back") {
          return "";
        } else if (this.current_projection === "left") {
          return "Back crop";
        }
      } else if (this.current_step === "measure") {
        return "Previous";
      }
    },
    getNextStepText() {
      if (this.current_step === "crop") {
        if (this.current_projection === "back") {
          return "Side crop";
        } else if (this.current_projection === "left") {
          return "Measure";
        }
      } else if (this.current_step === "measure") {
        return "Next";
      }
    },
    returnToCropping() {
      this.current_projection = "left";
      this.current_step = "crop";
    },
    goToPreviousStep() {
      this.setActiveCoordinates({}); //reset activeCoordinates
      if (this.current_step === "crop") {
        if (this.current_projection === "left") {
          this.current_projection = "back";
        }
      } else if (this.current_step === "measure") {
        this.previousMeasureStep();
        this.updateBodyprint();
      }
    },
    goToNextStep() {
      this.setActiveCoordinates({}); //reset activeCoordinates
      if (this.current_step === "crop") {
        if (this.current_projection === "back") {
          this.current_projection = "left";
        } else if (this.current_projection === "left") {
          this.current_projection = "back";
          this.current_step = "measure";
          this.measure_step = 0;
          this.setStepMeasure();
        }
      } else if (this.current_step === "measure") {
        this.nextMeasureStep();
        this.updateBodyprint();
      }
    },
    nextMeasureStep() {
      this.measure_step = this.getMeasureStep + 1;
      this.setStepMeasure();
    },
    previousMeasureStep() {
      this.measure_step = this.getMeasureStep - 1;
      this.setStepMeasure();
    },
    setStepMeasure() {
      const payload = {
        areas: this.getAreas,
        step: this.measure_step,
        projection: this.current_projection
      };
      this.updateConstraints(payload);
      this.setMeasureStep(payload);
    },
    saveBodyprint() {
      this.postBodyprint().then(() => {
        this.toEndScreen();
      });
    },
    rejectBodyPrint(reason) {
      this.showProblemModal = false;
      var payload = {
        status: "REJECTED",
        rejection_reason: reason
      };
      this.setQueueItemStatus(payload).then(() => this.toEndScreen());
    },
    stopEditing() {
      this.cancelQueueItem().then(() => this.toEndScreen());
    },
    completeBodyPrint() {
      this.postBodyprint().then(() => {
        var payload = { status: "COMPLETED" };
        this.setQueueItemStatus(payload).then(() => this.toEndScreen());
      });
      // this.toEndScreen()
    },
    updateBodyprint() {
      this.postBodyprint().then(() => {
        this.measurementsLoading = true;
        const params = {
          user: this.getBodyPrint.user,
          bodyprint: this.getBodyPrint._id
        };
        setTimeout(() => {
          this.getBodyprintSizes(params).then(() => {
            this.measurementsLoading = false;
          });
        }, 1000);
      });
    },
    toEndScreen() {
      this.$router.replace({
        name: ROUTE_BODYPRINT_QUEUE
      });
    },
    logout() {
      this.$store.dispatch("auth/authlogout");
    },
    handleKeydown(e) {
      switch (e.code) {
        case "Enter":
          e.preventDefault();
          this.goToNextStep();
          break;
        case "Backspace":
          e.preventDefault();
          this.goToPreviousStep();
          break;
      }
    },
    prepareMeasurementsModal() {
      this.updateBodyprint();
      this.showMeasurementsModal = true;
      this.measurementsBodyprint = this.getBodyPrint._id;
    },
    imageFlippedSet() {
      if (this.getArea.projection === "left") {
        return this.side_image_flipped;
      } else if (this.getArea.projection === "back") {
        return this.back_image_flipped;
      }
      return true;
    }
  }
};
</script>

<style></style>
