<template>
  <div
    class="relative overflow-hidden"
    :style="maxWidthandHeight"
    @click="onClickOutsideSet"
  >
    <!-- :crop="getGuide(this.getArea.projection).crop" -->
    <bodyprint-skeleton
      class="absolute"
      :projection="this.getArea.projection"
      :zoom="getAreaZoom"
      :crop="getGuide(this.getArea.projection).crop"
      :width="getPictureWidth"
      :heightZoom="getBasicZoom"
      :addTopZero="false"
      :style="skeletonStyles"
    />
    <!-- in property add if need to be other color  -->
    <measure-set-point
      :projection="this.getArea.projection"
      :offset="getOffset"
      :cropStyle="zoomStyles"
      :areaZoom="getAreaZoom"
      :cropWidth="cropWidth"
      :cropHeight="cropHeight"
      style="position: absolute; color: red; z-index: 1"
      :flipped="imageFlipped"
    ></measure-set-point>
    <div class="overflow-hidden" :style="viewMeasureSet">
      <div :style="viewMeasure">
        <!-- data:image/png;base64, -->
        <!-- :src="getGuide(this.getArea.projection).image_source" -->
        <img
          :src="this.getImage(this.getArea.projection)"
          :style="measureImage"
          alt="Projection image"
        />
      </div>
    </div>
    <measure-steps
      class="absolute bottom-0 z-50"
      :projection="getArea.projection"
    />
  </div>
</template>

<script>
import MeasureSteps from "./measure-steps.vue";
import MeasureSetPoint from "./measure-set-point.vue";
import BodyprintSkeleton from "../bodyprint/bodyprint-skeleton.vue";
import { mapGetters, mapMutations, mapState } from "vuex";

export default {
  name: "MeasureSet",
  props: {
    imageFlipped: Boolean
  },
  data() {
    return {};
  },
  components: {
    MeasureSteps,
    MeasureSetPoint,
    BodyprintSkeleton
  },
  computed: {
    ...mapGetters({
      getArea: "configuration/getArea",
      getGuide: "bodyprint/getGuide",
      getImage: "bodyprint/getImage",
      getPictureHeight: "bodyprint/getPictureHeight",
      getPictureWidth: "bodyprint/getPictureWidth"
    }),
    ...mapState({ zoom: "bodyprint/zoom" }),
    viewMeasure() {
      return {
        // overflow: 'hidden',
        // position: 'relative',
        maxHeight: "1000px",
        height: this.getPictureHeight + "px",
        width: this.getPictureWidth * 0.6 + "px",
        zIndex: 0
      };
    },
    viewMeasureSet() {
      return {
        overflow: "hidden",
        height: this.getPictureHeight + "px",
        width: this.getPictureHeight * 0.6 + "px"
      };
    },
    maxWidthandHeight() {
      return {
        maxHeight: this.getPictureHeight,
        maxWidth: this.getPictureHeight * 0.6
      };
    },
    measureImage() {
      let payload = {
        height: this.getPictureHeight * this.getAreaZoom + "px",
        width: this.getPictureWidth * this.getAreaZoom + "px",
        marginTop:
          (this.getCropStyle.marginTop + this.zoomStyles.marginTop) *
            this.getAreaZoom +
          this.getOffset.marginTop +
          "px",
        marginLeft:
          (this.getCropStyle.marginLeft + this.zoomStyles.marginLeft) *
            this.getAreaZoom +
          this.getOffset.marginLeft +
          "px",
        transform: this.imageFlipped ? "scaleX(-1)" : "scale(1)"
      };
      this.setMeasureImage(payload); //test causes infinite re render?
      return payload;
    },
    skeletonStyles() {
      return {
        marginTop:
          this.zoomStyles.marginTop * this.getAreaZoom +
          this.getOffset.marginTop +
          "px",
        marginLeft:
          this.zoomStyles.marginLeft * this.getAreaZoom +
          this.getOffset.marginLeft +
          "px",
        zIndex: 1
      };
    },
    zoomStyles() {
      //   return { marginTop: 0, marginLeft: 0 };
      return {
        marginTop:
          -1 *
          this.getArea.zoom.y *
          this.getPictureHeight *
          this.getGuide(this.getArea.projection).crop.height,
        marginLeft:
          -1 *
          this.getArea.zoom.x *
          this.getPictureWidth *
          this.getGuide(this.getArea.projection).crop.width
      };
    },
    getBasicZoom() {
      const xZoom = 1 / this.getArea.zoom.width;
      const yZoom = 1 / this.getArea.zoom.height;
      if (yZoom < xZoom) {
        return yZoom;
      }
      return xZoom;
    },
    getAreaZoom() {
      let zoom =
        this.getGuide(this.getArea.projection).crop.growthFactor *
        this.getBasicZoom;
      this.setZoom(zoom);
      return zoom;
    },
    getCropStyle() {
      return {
        marginTop:
          -1 *
          this.getGuide(this.getArea.projection).crop.y *
          this.getPictureHeight,
        marginLeft:
          -1 *
          this.getGuide(this.getArea.projection).crop.x *
          this.getPictureWidth
      };
    },
    cropWidth() {
      return (
        this.getPictureWidth *
        this.getGuide(this.getArea.projection).crop.width *
        this.getAreaZoom
      );
    },
    cropHeight() {
      return (
        this.getPictureHeight *
        this.getGuide(this.getArea.projection).crop.height *
        this.getAreaZoom
      );
    },
    getOffset() {
      //return { marginTop: 0, marginLeft: 0 };
      const measureHeight = this.getPictureHeight;
      let measureWidth = this.getPictureWidth;
      // let marginLeft = measureWidth * this.getArea.zoom.width;
      // if (this.getArea.zoom.width == 1) {
      //   marginLeft = measureWidth / 2;
      // }

      if (this.getArea.zoom.width === 1) {
        measureWidth = 0;
      }

      return {
        marginTop:
          measureHeight / 2 -
          (measureHeight * this.getArea.zoom.height) / 2 -
          this.getPictureHeight * 0.1,
        marginLeft:
          this.getPictureHeight * 0.3 -
          (measureWidth * this.getArea.zoom.width) / 2
      };
    }
  },
  methods: {
    ...mapMutations({
      setMeasureImage: "bodyprint/setMeasureImage",
      setZoom: "bodyprint/setZoom",
      deactivatePoints: "bodyprint/deactivatePoints"
    }),
    onClickOutsideSet(event) {
      var isMeasurePoint = false;
      event.composedPath().forEach(element => {
        if (element.classList) {
          element.classList.forEach(c => {
            if (c.includes("point-left") || c.includes("point-back")) {
              isMeasurePoint = true;
            }
          });
        }
      });
      if (!isMeasurePoint) {
        this.deactivatePoints();
      }
    }
  }
};
</script>
<style>
/* .view-measure-set {
  overflow: hidden;
  height: 1200px;
  width: 600px; */
/* position: relative; */
/* border: 2px solid red; */
/* margin-left: 50px; */
/* } */
</style>
